
import { defineComponent, ref, inject } from "vue";
import UiInput from "@/components/UI/Input";
import UiCode from "@/components/UI/Code";
import UiButton from "@/components/UI/Button";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import qs from "qs";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "Forget",
  components: {
    UiInput,
    UiCode,
    UiButton,
  },
  setup() {
    const mobile = ref("");
    const code = ref("");
    const pwd = ref("");
    const repeatPwd = ref("");
    const router = useRouter();
    //  展示用户信息
    const userInfo = inject("userInfo") as any;
    if (userInfo.userid) {
      axios
        .get("M/User/ChangeMobile")
        .then((res) => {
          if (res.data.success) {
            mobile.value = res.data.obj.my.mobile;
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
         console.log(err)
        });
    }

    function checkCode(code: string) {
      const reg = /^[0-9]{6}$/;
      console.log(code);
      if (!code || !reg.test(code)) {
        Toast({
          type: "warning",
          title: "请输入正确的验证码",
        });
        return false;
      }

      return true;
    }
    function checkPwd(pwd: string, rePwd: string) {
      if (pwd != rePwd) {
        Toast({
          type: "warning",
          title: "两次密码不一致，请检查",
        });
        return false;
      }
      return true;
    }
    function onSubmit() {
      if (!checkCode(code.value) || !checkPwd(pwd.value, repeatPwd.value)) {
        return false;
      }
      const data = qs.stringify({
        tel: mobile.value,
        code: code.value,
        newpass: repeatPwd.value,
        prepass: pwd.value,
      });
      axios
        .post("/M/Server/DoForgetPassWord", data)
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
              onClose: () => {
                localStorage.removeItem("token");
                router.push({
                  name: "Mine",
                });
              },
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: err.message || err,
          });
        });
    }
    
    return {
      mobile,
      code,
      pwd,
      repeatPwd,
      onSubmit
    };
  },
});
